export const getters = {
  authenticated(state) {
    return state.auth.loggedIn
  },
  user(state) {
    return state.auth.user
  },
}

export const state = () => ({
  token: null,
  is_refresh_called: false,
  medias: [],
})

export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_IS_REFRESH_CALLED(state, val) {
    state.is_refresh_called = val
  },
}

export const actions = {
  async setToken({ commit }, { access_token, expires_in }) {
    this.$axios.setToken(access_token, 'Bearer')
    await this.$auth.setUserToken(access_token, access_token)
    commit('SET_TOKEN', access_token)
    commit('SET_IS_REFRESH_CALLED', false)
  },
  async refreshToken(param) {
    // this.$auth.refreshTokens();
    if (param.state.is_refresh_called) {
      param.dispatch('logout')
    } else {
      param.commit('SET_IS_REFRESH_CALLED', true)
      const data = await this.$axios.$post('auth/refresh')
      if (data) {
        param.dispatch('setToken', { access_token: data.access_token, expires_in: data.expires_in })
      } else {
        param.dispatch('logout')
      }
    }
  },
  logout({ commit }) {
    this.$axios.setToken(false)
    commit('SET_TOKEN', null)
  },
}

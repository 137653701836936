import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61bf0f0a = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _65c69784 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _2f76e742 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _a298a82e = () => interopDefault(import('../pages/jobs.vue' /* webpackChunkName: "pages/jobs" */))
const _7adea674 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _0021ebec = () => interopDefault(import('../pages/nojob_index.vue' /* webpackChunkName: "pages/nojob_index" */))
const _f5f6474e = () => interopDefault(import('../pages/Register/index.vue' /* webpackChunkName: "pages/Register/index" */))
const _58232446 = () => interopDefault(import('../pages/my/account-settings/index.vue' /* webpackChunkName: "pages/my/account-settings/index" */))
const _138d475c = () => interopDefault(import('../pages/my/profile/index.vue' /* webpackChunkName: "pages/my/profile/index" */))
const _4e76ea30 = () => interopDefault(import('../pages/my/resume/index.vue' /* webpackChunkName: "pages/my/resume/index" */))
const _15aac0c9 = () => interopDefault(import('../pages/my/profile/savlon.vue' /* webpackChunkName: "pages/my/profile/savlon" */))
const _3e3e6dba = () => interopDefault(import('../pages/job/_id/_slug/index.vue' /* webpackChunkName: "pages/job/_id/_slug/index" */))
const _241e099a = () => interopDefault(import('../pages/Register/_hash/_otp/index.vue' /* webpackChunkName: "pages/Register/_hash/_otp/index" */))
const _fd122c62 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _61bf0f0a,
    name: "about"
  }, {
    path: "/contact-us",
    component: _65c69784,
    name: "contact-us"
  }, {
    path: "/forgot-password",
    component: _2f76e742,
    name: "forgot-password"
  }, {
    path: "/jobs",
    component: _a298a82e,
    name: "jobs"
  }, {
    path: "/Login",
    component: _7adea674,
    name: "Login"
  }, {
    path: "/nojob_index",
    component: _0021ebec,
    name: "nojob_index"
  }, {
    path: "/Register",
    component: _f5f6474e,
    name: "Register"
  }, {
    path: "/my/account-settings",
    component: _58232446,
    name: "my-account-settings"
  }, {
    path: "/my/profile",
    component: _138d475c,
    name: "my-profile"
  }, {
    path: "/my/resume",
    component: _4e76ea30,
    name: "my-resume"
  }, {
    path: "/my/profile/savlon",
    component: _15aac0c9,
    name: "my-profile-savlon"
  }, {
    path: "/job/:id?/:slug",
    component: _3e3e6dba,
    name: "job-id-slug"
  }, {
    path: "/Register/:hash/:otp",
    component: _241e099a,
    name: "Register-hash-otp"
  }, {
    path: "/",
    component: _fd122c62,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

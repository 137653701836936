export default function ({ $axios, store }) {
  $axios.onError(async (error) => {
    if (error.response?.status === 422) {
      await store.dispatch('validation/setErrors', error.response?.data?.errors)
    } else if (error.response?.status === 401) {
      // store.dispatch('logout');
      await store.dispatch('refreshToken')
      return $axios
    }
    return Promise.reject(error)
  })

  $axios.onRequest((config) => {
    store.dispatch('validation/clearErrors')
    config.headers.common['X-Authorization'] = process.env.API_KEY
  })
}

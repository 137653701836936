<template>
  <client-only>
    <div>
      <Top></Top>
      <div class="content">
        <nuxt />
      </div>
      <Footer></Footer>
    </div>
  </client-only>
</template>

<script>
import Top from '@/components/Top'
import Footer from '@/components/Footer'
export default {
  name: 'client_site',
  components: { Top, Footer },
  data() {
    return {
      candidate: new Object(null),
    }
  },
  methods: {
    loadCandidate() {
      let that = this
      this.$axios.get('/profile/get').then((response) => {
        that.$store.dispatch('candidate/setCandidateData', response.data.data)
      })
    },
  },
  mounted() {
    if (this.$store?.$auth?.$state?.loggedIn) this.loadCandidate()
  },
}
</script>
